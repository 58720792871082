<template>
  <v-container fluid>
    <div class="d-flex mt-4" v-if="filteredProducts">
      <v-autocomplete
        class="mr-2"
        v-model="selectedArtist"
        :items="artists"
        outlined
        clearable
        clear-icon="mdi-close-box"
        dense
        label="Artist"
      ></v-autocomplete>
      <v-autocomplete
        class="mr-2"
        v-model="selectedProductType"
        :items="productTypes"
        outlined
        clearable
        clear-icon="mdi-close-box"
        dense
        label="Product"
      ></v-autocomplete>
      <v-autocomplete
        class="mr-2"
        v-model="selectedStatus"
        :items="statuses"
        outlined
        clearable
        clear-icon="mdi-close-box"
        dense
        label="Status"
      ></v-autocomplete>
      <v-text-field
        class="mr-2"
        v-model="searchText"
        label="Search"
        dense
        outlined
        clearable
      ></v-text-field>
      <v-btn  color="success" @click.native="clearFilters()">
          Reset
        </v-btn>
    </div>
    <v-data-table
      v-if="filteredProducts"
      v-model="selectedProducts"
      :search="searchText"
      :items="filteredProducts"
      :loading="loadingData"
      :headers="headers"
      :footer-props="{
        itemsPerPageOptions: [25, 50, 100]
      }"
      dense
    >
      <template v-slot:[`item.productImage`]="{ item }">
        <v-img
          class="my-2"
          max-height="50px"
          max-width="50px"
          :src="`${imageUrl}/100px/${item.productImage || item.artworkImage}`"
        ></v-img>
      </template>
      <template v-slot:[`item.sku`]="{ item }">
        <span style="white-space: nowrap">{{item.sku}}</span>
      </template>
      <template v-slot:[`item.artist`]="{ item }">
      {{item.artist}}
      </template>
      <template v-slot:[`item.title`]="{ item }">
        {{item.title}}
      </template>
      <template v-slot:[`item.manageStatus`]="{ item }">
      <div class="d-flex justify-end">
        <v-btn v-if="item.status !=='ACTIVE' && item.productImage && item.productImages.length > 0" small color="success" class="ml-2" @click.native="setStatus({product: item, status: 'ACTIVE'})">
          active
        </v-btn>
        <v-btn v-if="item.status !=='ARCHIVED'" small color="success" class="ml-2" @click.native="setStatus({product: item, status: 'ARCHIVED'})">
          archived
        </v-btn>
        <v-btn v-if="item.status !=='PENDING'" small color="success" class="ml-2" @click.native="setStatus({product: item, status: 'PENDING'})">
          pending
        </v-btn>
      </div>
      </template>
      <template v-slot:[`item.barCode`]="{ item }">
        <vue-barcode style="display: none" class="v-clickable" ref="barcode" :value="item.barCode" format="EAN13" height="30" />
        {{item.barCode}}
        <v-btn icon color="success" @click.native="barcodeDownload(item)">
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
      <div class="d-flex justify-end">
        <v-btn small color="success" @click.native="editProduct(item)">
          Edit
        </v-btn>
        </div>
      </template>
    </v-data-table>
    <product-dialog
      v-if="openDialog"
      v-model="openDialog"
      :variant="currentProduct.variant"
      :sku="currentProduct.sku || null"
      @close="closeDialog"
    />
  </v-container>
</template>

<script>
import ProductDialog from "./components/ProductDialog";
import ProductApi from "@/api/admin/product";
import CartApi from "@/api/customer/cart";
import { svgToImgDownload } from '@/utilities/imageUtilities'
import VueBarcode from '@/components/vue-barcode'

export default {
  components: {
    ProductDialog,
    VueBarcode
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      searchText: null,
      selectedArtist: null,
      selectedProductType: null,
      selectedStatus: null,
      products: [],
      openDialog: false,
      currentProduct: null,
      selectedProducts: [],
      headers: [
        { text: "", value: "productImage" },
        { text: "Product", value: "variant" },
        { text: "Sku", value: "sku" },
        { text: "Artist", value: "artist" },
        { text: "Title", value: "title" },
        { text: "Barcode", value: "barCode" },
        { text: "Status", value: "status" },
        { text: "Set Status", value: "manageStatus", align: "center" },
        { text: "", value: "edit" },
      ],
      selectedFile: null,
      validData: false,
      valid: true,
      processing: false,
      progress: 0,
      loadingData: true,
      mode: "preview",
    };
  },
  computed: {
    filter () {
      const obj = {}
      this.selectedArtist && (obj.artist = this.selectedArtist)
      this.selectedProductType && (obj.category = this.selectedProductType)
      this.selectedStatus && (obj.status = this.selectedStatus)
      return obj
    },
    filteredProducts () {
      return _.filter(this.products, this.filter)
    },
    artists () { return _.uniq(this.filteredProducts.map(a => a.artist)) },
    productTypes () { return _.uniq(this.filteredProducts.map(a => a.category).sort()) },
    statuses () { return _.uniq(this.filteredProducts.map(a => a.status)) },

  },
  async created() {
    ProductApi.loadAllProducts()
      .then((results) => {
        this.products = results.data;
        this.loadingData = false;
      })
      .catch((err) => {
        console.log("err", err);
        this.loadingData = false;
      });
  },
  mounted: function () {},
  watch: {},
  methods: {
    clearFilters() {
      this.selectedArtist = null
      this.selectedProductType = null
      this.selectedStatus = null
      this.searchText = null
    },
    barcodeDownload(item) {
      const svg = document.querySelector(`.vue-barcode-${item.barCode}`);
      svgToImgDownload( svg, `${item.sku}-BARCODE.jpg` , 500, 200 )
    },
    setStatus({product,status}) {
      product.status = status;
      ProductApi.setProductStatus(product)
      .then( async (results) => {
        this.loadingData = false
      }).catch(
        err => {
          console.log('err', err)
          this.loadingData = false
        }
      )
    },
    async saveProduct(product) {
      ProductApi.saveProduct(product)
      .then( async (results) => {
        this.loadingData = false
      }).catch(
        err => {
          console.log('err', err)
          this.loadingData = false
        }
      )
    },
    editProduct(evt) {
      this.currentProduct = {...evt};
      this.openDialog = true;
    },
    closeDialog(element) {
      this.loadingData = true;
      this.currentProduct = null;
      this.openDialog = false;
      ProductApi.loadAllProducts()
        .then((results) => {
          this.products = results.data;
          this.loadingData = false;
        })
        .catch((err) => {
          console.log("err", err);
          this.loadingData = false;
        });
      },
  },
};
</script>
